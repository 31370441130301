import { DynamicPageProps } from '@doltech/routeDefinition';
import { LandingLayoutContent } from '@doltech/ui/lib/figma/Layout/Landing/LandingLayout';

import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import * as React from 'react';

import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import dynamic from 'next/dynamic';
import { FABLandingHome } from '../landing-home/components/FABLandingHome';
import { HomeHeroBannerBeforeLogin } from './components/HomeHeroBannerBeforeLogin';

const HomeHeroBannerAfterLogin = dynamic(
  import('./components/HomeHeroBannerAfterLogin').then((mod) => mod.HomeHeroBannerAfterLogin),
  {
    ssr: false,
  }
);

interface HomePageLandingProps
  extends DynamicPageProps,
    Pick<React.HTMLAttributes<HTMLDivElement>, 'children'> {}

export const HomePageLanding = observer((props: HomePageLandingProps) => {
  const { children, seoData } = props;
  const { isLoggedIn } = useGetAuthInfo();

  const router = useRouter();
  if (router.isFallback) {
    return <div>loading...</div>;
  }

  return (
    <main>
      <FABLandingHome />
      <LandingLayoutContent.Container fullWidth>
        {isLoggedIn ? (
          <HomeHeroBannerAfterLogin />
        ) : (
          <HomeHeroBannerBeforeLogin seoData={seoData} />
        )}
      </LandingLayoutContent.Container>
      <LandingLayoutContent.Container className="section-sub-content">
        {children}
      </LandingLayoutContent.Container>
    </main>
  );
});
