import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import { ResponsiveTypography } from '../../../../Typography/v3/ResponsiveTypography';
import { ButtonStyles } from '../../../..//Button/ButtonStyles/ButtonStyles';
import { ButtonSizableContent } from '../../../../Button/ButtonGeneral/ButtonSizableContent';
import { colorsV2 } from '../../../../colors-v2';
import { IconOtherClose } from '@doltech/icons/IconOtherClose';
import { Link } from '../../../../Link';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

const Main = styled.div`
  background-color: ${colorsV2.primary10};
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  &.almost-expired {
    background-color: ${colorsV2.blue10};
  }
  ${fromScreen(768)} {
    padding: 12px 24px;
  }
  ${fromScreen(1128)} {
    padding: 12px 0;
  }
`;
const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  align-items: center;
  gap: 16px;

  .left-side {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    gap: 16px;
  }
  .highlight {
    font-weight: 600;
  }

  .close-btn {
    cursor: pointer;
  }
  ${fromScreen(1128)} {
    max-width: 1080px;
  }
`;

interface PromotionBarProps {
  type: 'sign-up' | 'view-course' | 'expired' | 'almost-expired';
  text: React.ReactNode;
  actionTitle: string;
  actionHref?: string;
  actionOnClick?: () => void;
  onClose?: () => void;
}

const Action = ({ actionHref, actionOnClick, actionTitle, type }) => {
  if (actionOnClick) {
    return (
      <ButtonStyles
        buttonStyle="filled"
        color={type === 'almost-expired' ? 'blue' : 'primary'}
        colorStyle="bold"
        content={<ButtonSizableContent text={actionTitle} size="small" />}
        onClick={actionOnClick}
      />
    );
  }

  if (actionHref) {
    return (
      <Link href={actionHref} target="_blank">
        <ButtonStyles
          buttonStyle="filled"
          color={type === 'almost-expired' ? 'blue' : 'primary'}
          colorStyle="bold"
          content={<ButtonSizableContent text={actionTitle} size="small" />}
        />
      </Link>
    );
  }

  return null;
};

export const PromotionBar = (props: PromotionBarProps) => {
  const { type, text, actionTitle, actionHref, actionOnClick, onClose } = props;
  return (
    <Main className={cl('promotion-bar', type)}>
      <Container>
        <div className="left-side">
          <ResponsiveTypography.Paragraph
            variant={['regular/14-20', 'regular/16-24']}
            breakpoints={[0, 768]}
            className="text"
            color="black100"
          >
            {text}
          </ResponsiveTypography.Paragraph>
          <Action
            actionOnClick={actionOnClick}
            actionHref={actionHref}
            actionTitle={actionTitle}
            type={type}
          />
        </div>
        <div className="close-btn" onClick={onClose}>
          <IconOtherClose size={20} color="black80" />
        </div>
      </Container>
    </Main>
  );
};
