import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { useUserAuthentication } from '@doltech/ui/lib/figma/Auth/hocs/withUserAuthentication';
import { ButtonGeneral } from '@doltech/ui/lib/figma/Button/ButtonGeneral/ButtonGeneral';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { Typography } from '@doltech/ui/lib/figma/DesignSystemV2/Typography';

import { s3ToCDN } from '@doltech/utils/lib/url';
import * as React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  width: 100%;
  background-color: ${colorsV2.paper};
  padding: 0 16px;

  ${fromScreen(768)} {
    padding: 0 24px;
  }
`;

const BannerContent = styled.div`
  position: relative;

  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 32px 0;
  .left-side {
    max-width: 648px;
  }

  .banner-img {
    position: absolute;
    display: none;
    height: 100%;
    top: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ${fromScreen(768)} {
    padding: 132px 0 140px;
    .banner-img {
      right: -220px;
      display: block;
    }
  }
  ${fromScreen(960)} {
    .banner-img {
      right: 0;
    }
  }
`;

const TitleWrapper = styled.div`
  .home-title {
    display: flex;
    flex-direction: column;
  }

  .highlight {
    color: ${colorsV2.primary100};
  }

  .underline {
    display: inline-block;
    padding: 0 2px;
    background-image: linear-gradient(
      120deg,
      rgb(255, 153, 31, 0.35) 0%,
      rgb(255, 153, 31, 0.35) 100%
    );
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-;
  }
`;

const DescriptionWrapper = styled.div`
  &&& {
    .home-desc {
      margin-top: 16px;
      margin-bottom: 20px;
      ${fromScreen(768)} {
        margin-top: 20px;
        margin-bottom: 36px;
      }
    }
  }
`;

const BannerHeader = ({ title, description }) => {
  return (
    <>
      <TitleWrapper>
        <Typography.Title
          className="home-title"
          level={1}
          variant="bold/32-40"
          tabletVariant="bold/40-48"
          color="black100"
        >
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Typography.Title>
      </TitleWrapper>
      <DescriptionWrapper>
        <Typography.Text
          variant="regular/14-20"
          tabletVariant="regular/18-28"
          color="black80"
          className="home-desc"
          as="p"
        >
          {description}
        </Typography.Text>
      </DescriptionWrapper>
    </>
  );
};

const BannerHeaderNoHydrate = BannerHeader;

export const HomeHeroBannerBeforeLogin = ({ seoData }) => {
  const { title, description } = seoData.dol;
  const { openLoginModal } = useUserAuthentication();

  return (
    <Main className="home-hero-banner-before-login">
      <BannerContent>
        <div className="left-side">
          <BannerHeaderNoHydrate title={title} description={description} />
          <ButtonGeneral
            type="primary"
            size="large"
            text="Đăng ký miễn phí ngay"
            onClick={openLoginModal}
          />
        </div>
        <div className="banner-img">
          <img
            src={s3ToCDN(
              'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/PUBLIC/MEDIA/Frame_50126660_cd770457d6.png'
            )}
            alt="Nền tảng tự học IELTS Online top 1 Việt Nam"
          />
        </div>
      </BannerContent>
    </Main>
  );
};
