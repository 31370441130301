import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { ButtonStyles } from '../ButtonStyles/ButtonStyles';
import { ButtonSizeProps } from '../ButtonSize/ButtonSize';
import { IconButtonContentGeneralProps } from '../ButtonContentGeneral/ButtonContentGeneral';
import { ButtonSizableContent } from './ButtonSizableContent';

const Container = styled.button`
  padding: 0;
  border: none;
  background: transparent;
`;

type ExtendsInterface = Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick' | 'onMouseDown' | 'disabled' | 'className'
> &
  Pick<ButtonSizeProps, 'size' | 'textOnly' | 'iconOnly'> &
  Pick<IconButtonContentGeneralProps, 'iconLeft' | 'iconRight' | 'text'>;

interface ButtonGeneralProps extends ExtendsInterface {
  type?: 'primary' | 'secondary' | 'secondary-filled' | 'tertiary' | 'ghost';
  htmlType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  transparent?: boolean;
  loading?: boolean;
}

export const ButtonGeneral = (props: ButtonGeneralProps) => {
  const {
    className,
    type = 'ghost',
    htmlType = 'button',
    size,
    text,
    iconLeft,
    iconRight,
    textOnly = false,
    iconOnly = false,
    transparent = false,
    loading = false,
    disabled = false,
    ...rest
  } = props;

  switch (type) {
    case 'primary':
      return (
        <Container
          className={cl('button-general', className)}
          type={htmlType}
          disabled={disabled || loading}
          {...rest}
        >
          <ButtonStyles
            buttonStyle="filled"
            color="primary"
            colorStyle="bold"
            transparent={transparent}
            loading={loading}
            content={
              <ButtonSizableContent
                loading={loading}
                iconLeft={iconLeft}
                iconRight={iconRight}
                size={size}
                text={text}
                textOnly={textOnly}
                iconOnly={iconOnly}
              />
            }
            disabled={disabled}
          />
        </Container>
      );
    case 'secondary':
      return (
        <Container
          className={cl('button-general', className)}
          type={htmlType}
          disabled={disabled || loading}
          {...rest}
        >
          <ButtonStyles
            buttonStyle="outline"
            color="dark"
            colorStyle="matte"
            transparent={transparent}
            loading={loading}
            content={
              <ButtonSizableContent
                loading={loading}
                iconLeft={iconLeft}
                iconRight={iconRight}
                size={size}
                text={text}
                textOnly={textOnly}
                iconOnly={iconOnly}
              />
            }
            disabled={disabled}
          />
        </Container>
      );
    case 'secondary-filled':
      return (
        <Container
          className={cl('button-general', className)}
          type={htmlType}
          disabled={disabled || loading}
          {...rest}
        >
          <ButtonStyles
            buttonStyle="filled"
            color="dark"
            colorStyle="matte"
            transparent={transparent}
            loading={loading}
            content={
              <ButtonSizableContent
                loading={loading}
                iconLeft={iconLeft}
                iconRight={iconRight}
                size={size}
                text={text}
                textOnly={textOnly}
                iconOnly={iconOnly}
              />
            }
            disabled={disabled}
          />
        </Container>
      );
    case 'tertiary':
      return (
        <Container
          className={cl('button-general', className)}
          type={htmlType}
          disabled={disabled || loading}
          {...rest}
        >
          <ButtonStyles
            buttonStyle="filled"
            color="blue"
            colorStyle="bold"
            transparent={transparent}
            loading={loading}
            content={
              <ButtonSizableContent
                loading={loading}
                iconLeft={iconLeft}
                iconRight={iconRight}
                size={size}
                text={text}
                textOnly={textOnly}
                iconOnly={iconOnly}
              />
            }
            disabled={disabled}
          />
        </Container>
      );
    case 'ghost':
    default:
  }

  return (
    <Container
      className={cl('button-general', className)}
      type={htmlType}
      disabled={disabled}
      {...rest}
    >
      <ButtonStyles
        buttonStyle="filled"
        color="blue"
        colorStyle="bold"
        transparent
        loading={loading}
        content={
          <ButtonSizableContent
            loading={loading}
            iconLeft={iconLeft}
            iconRight={iconRight}
            size={size}
            text={text}
            textOnly={textOnly}
            iconOnly={iconOnly}
          />
        }
        disabled={disabled}
      />
    </Container>
  );
};
