import { RefObject } from 'react';
import * as React from 'react';
import { off, on } from 'react-use/lib/misc/util';

export const useScrollCallback = (ref: RefObject<HTMLElement | Window>, handler): void => {
  React.useEffect(() => {
    if (ref.current) {
      on(ref.current, 'scroll', handler, {
        capture: false,
        passive: true,
      });
    }

    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        off(ref.current, 'scroll', handler);
      }
    };
  }, [handler, ref]);
};
