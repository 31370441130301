import { useAppScrollRef } from '@doltech/core/lib/appScroll/withAppScroll';
import { useScrollCallback } from '@doltech/core/lib/hooks/useScrollCallback';
import { getScrollYOf } from '@doltech/core/lib/hooks/useScrollElement';
import { requestIdleCallbackForSafari } from '@doltech/utils/lib/platform';
import * as React from 'react';

const HIDE_CLASSNAME = 'hide';

interface UseLandingHeaderDisplayAsHeadRoomParams {
  headerId?: string;
  bannerId?: string;
}

const showElm = (headerId: string) => {
  if (headerId) {
    const headerElm = document.getElementById(headerId);
    if (headerElm) {
      headerElm.classList.remove(HIDE_CLASSNAME);
    }
  }
};

const hideElm = (headerId: string) => {
  if (headerId) {
    const headerElm = document.getElementById(headerId);
    if (headerElm && !headerElm.classList.contains(HIDE_CLASSNAME)) {
      headerElm.classList.add(HIDE_CLASSNAME);
    }
  }
};

const getElmHeight = (element: HTMLElement, defaultValue = 0) => {
  if (element) {
    return element.getBoundingClientRect().height;
  }
  return defaultValue;
};

export const useBannerScroll = ({
  headerId,
  bannerId,
}: UseLandingHeaderDisplayAsHeadRoomParams) => {
  const { appScrollRef } = useAppScrollRef();
  const bannerHeight = React.useRef<number>();
  const headerHeight = React.useRef<number>();
  const lastScrollYRef = React.useRef<number>();
  const showHideHeader = React.useCallback(() => {
    const shouldShowBanner = getScrollYOf(appScrollRef?.current) > bannerHeight.current;
    if (shouldShowBanner) {
      showElm(bannerId);
    } else {
      hideElm(bannerId);
    }
    lastScrollYRef.current = getScrollYOf(appScrollRef?.current);
  }, [appScrollRef, bannerId]);
  useScrollCallback(appScrollRef, showHideHeader);
  React.useEffect(() => {
    requestIdleCallbackForSafari(() => {
      bannerHeight.current = getElmHeight(document.getElementById(bannerId));
      headerHeight.current = getElmHeight(document.getElementById(headerId));
    });
  }, [bannerId, headerId]);
};
