import { HomePageComponent } from '@doltech/modules/lib/home-page/HomePageComponent';
import { HomeLandingLayout } from '@doltech/modules/lib/home-page/layout/HomeLandingLayout';
import { DynamicPageProps } from '@doltech/routeDefinition';
import styled from 'styled-components';
import * as React from 'react';

const Main = styled.section``;

interface HomePageTemplatePageProps extends DynamicPageProps {}

const HomePageTemplatePage = (props: HomePageTemplatePageProps) => {
  return <HomePageComponent {...props} />;
};

export default HomePageTemplatePage;

export const HomePageTemplatePageLayout = ({ children, ...pageProps }) => {
  return (
    <Main>
      <HomeLandingLayout {...(pageProps as any)}>{children}</HomeLandingLayout>
    </Main>
  );
};
