import { DynamicPageProps } from '@doltech/routeDefinition';
import { LandingLayoutDefault } from '@doltech/ui/lib/figma/Layout/Landing/PremadeLayout/LandingLayoutDefault';
import { PromotionBarShareBanner } from '@doltech/ui/lib/figma/Layout/Landing/ui/PromotionBarShareBanner/PromotionBarShareBanner';
import * as React from 'react';

import { HomePageLanding } from '../HomePageLanding';

interface HomeLandingLayoutProps extends React.HTMLAttributes<HTMLDivElement>, DynamicPageProps {
  preview?: boolean;
}

export const HomeLandingLayout = (props: HomeLandingLayoutProps) => {
  const { children, preview = false, ...rest } = props;
  return (
    <LandingLayoutDefault withHeader={!preview} {...rest}>
      <PromotionBarShareBanner />
      <LandingLayoutDefault.LimitContainer>
        <HomePageLanding {...rest}>{children}</HomePageLanding>
      </LandingLayoutDefault.LimitContainer>
    </LandingLayoutDefault>
  );
};
