import { SpokePageSpinner } from '@doltech/core/lib/auth/SpokePageSpinner';
import { DynamicPageProps } from '@doltech/routeDefinition';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { useLandingSharedDataStore } from '@doltech/ui/lib/figma/Layout/Landing/model/landing-shared-data.hook';
import { HomePageSectionEnum } from '@doltech/utils/lib/constants';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import * as React from 'react';
import styled from 'styled-components';

import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';

const HomePageComponentAfterLogin = dynamic<any>(
  () => import('./HomePageComponentAfterLogin').then((mod) => mod.HomePageComponentAfterLogin),
  { ssr: false, loading: () => <SpokePageSpinner /> }
);

const HomePageComponentContent = dynamic<any>(() =>
  import('./HomePageComponentContent').then((mod) => mod.HomePageComponentContent)
);

const HomePage = styled.div`
  background: ${colorsV2.white100};
  margin: 0 auto;
`;

export interface HomePageComponentProps extends DynamicPageProps {}

export const HomePageComponent = observer((props: HomePageComponentProps) => {
  const { pageData, seoData } = props;
  const landingStore = useLandingSharedDataStore();
  const { isLoggedIn } = useGetAuthInfo();
  const {
    homeNewsData,
    onlineTestData,
    dictationData,
    readingData,
    listeningData,
    writingSampleData,
    speakingSampleData,
  } = pageData;
  const { pageSections = [] } = seoData;

  const {
    onlineTestPageSection,
    dictationPageSection,
    listeningPracticeTestPageSection,
    readingPracticeTestPageSection,
  } = React.useMemo(() => {
    return {
      onlineTestPageSection: pageSections?.find(
        (item) => item.type === HomePageSectionEnum.ONLINE_TEST
      ),

      dictationPageSection: pageSections?.find(
        (item) => item.type === HomePageSectionEnum.DICTATION
      ),

      listeningPracticeTestPageSection: pageSections?.find(
        (item) => item.type === HomePageSectionEnum.LISTENING_PRACTICE_TEST
      ),

      readingPracticeTestPageSection: pageSections?.find(
        (item) => item.type === HomePageSectionEnum.READING_PRACTICE_TEST
      ),
    };
  }, [pageSections]);

  const renderContent = React.useCallback(() => {
    if (isLoggedIn) {
      return (
        <HomePageComponentAfterLogin
          homeNewsData={homeNewsData}
          onlineTestData={onlineTestData}
          onlineTestPageSection={onlineTestPageSection}
          dictationData={dictationData}
          dictationPageSection={dictationPageSection}
          listeningPracticeTestPageSection={listeningPracticeTestPageSection}
          readingPracticeTestPageSection={readingPracticeTestPageSection}
          writingSampleData={writingSampleData}
          speakingSampleData={speakingSampleData}
        />
      );
    }
    return (
      <HomePageComponentContent
        staticPageLink={landingStore?.pageLink?.staticPageLink || {}}
        dictationData={dictationData}
        dictationPageSection={dictationPageSection}
        homeNewsData={homeNewsData}
        readingData={readingData}
        listeningData={listeningData}
        onlineTestData={onlineTestData}
        onlineTestPageSection={onlineTestPageSection}
        listeningPracticeTestPageSection={listeningPracticeTestPageSection}
        readingPracticeTestPageSection={readingPracticeTestPageSection}
        writingSampleData={writingSampleData}
        speakingSampleData={speakingSampleData}
      />
    );
  }, [
    dictationData,
    dictationPageSection,
    homeNewsData,
    isLoggedIn,
    landingStore?.pageLink?.staticPageLink,
    onlineTestData,
    onlineTestPageSection,
    readingData,
    listeningData,
    writingSampleData,
    speakingSampleData,
    listeningPracticeTestPageSection,
    readingPracticeTestPageSection,
  ]);

  return <HomePage>{renderContent()}</HomePage>;
});
