import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';

import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { useBannerScroll } from '../../../../../hooks/useLandingHeaderDisplayAsHeadRoom';
import { useUserAuthentication } from '../../../../Auth/hocs/withUserAuthentication';
import { useGetReferralCode } from '../../../../ReferralShare/useGetReferralCode';
import { PromotionBar } from '../../ui/PromotionBar/PromotionBar';
import { useExpiredModal } from '../../hooks/useExpiredModal';

const BANNER_ID = 'referral-share-banner';

const Main = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  z-index: 200;

  transition: all 0.2s ease;

  &.closed {
    display: none;
  }

  &.hide {
    top: 0;
    opacity: 0;
    z-index: -1;
  }
`;

const toggleReferralBannerClass = (elm, isBannerClosed) => {
  if (!elm) return;
  if (isBannerClosed) {
    elm.classList.remove('has-referral-banner');
    return;
  }
  elm.classList.add('has-referral-banner');
};

export const PromotionBarShareBanner = observer(({ className }: any) => {
  const { isLoggedIn } = useGetAuthInfo();
  const { isExpired, remainingDays } = useGetReferralCode();
  const [isClosed, closeBanner] = React.useState(false);

  const { openLoginModal } = useUserAuthentication();
  const { openExtendPremiumModal } = useExpiredModal();

  const renderContent = React.useCallback(() => {
    if (isLoggedIn) {
      if (isExpired) {
        return (
          <PromotionBar
            type="expired"
            text="Tài khoản của bạn đã hết hạn, gia hạn ngay!!!"
            actionTitle="Gia hạn"
            actionOnClick={openExtendPremiumModal}
            onClose={() => closeBanner(true)}
          />
        );
      }
      if (remainingDays >= 0 && remainingDays <= 3) {
        return (
          <PromotionBar
            type="almost-expired"
            text={`Bạn chỉ còn ${remainingDays} ngày sử dụng, gia hạn ngay trước khi hết hạn!`}
            actionTitle="Gia hạn"
            actionOnClick={openExtendPremiumModal}
            onClose={() => closeBanner(true)}
          />
        );
      }
      return (
        <PromotionBar
          type="view-course"
          text={
            <>
              Tìm hiểu khóa học tại DOL và
              <span className="highlight"> nhận code tự học miễn phí</span>
            </>
          }
          actionTitle="Tư vấn"
          actionHref="https://m.me/dolenglish.ieltsdinhluc"
          onClose={() => closeBanner(true)}
        />
      );
    }

    return (
      <PromotionBar
        type="sign-up"
        text={
          <>
            Đăng ký tài khoản, trải nghiệm tính năng{' '}
            <span className="highlight"> premium miễn phí</span>
          </>
        }
        actionTitle="Đăng ký"
        actionOnClick={openLoginModal as any}
        onClose={() => closeBanner(true)}
      />
    );
  }, [isLoggedIn, isExpired, remainingDays, openExtendPremiumModal, openLoginModal]);

  useBannerScroll({
    bannerId: BANNER_ID,
  });
  React.useEffect(() => {
    if (isLoggedIn) {
      const elm = document.getElementsByClassName('landing-section-middle')?.[0];
      toggleReferralBannerClass(elm, isClosed);
    }
  }, [isClosed, isLoggedIn]);

  return (
    <Main id={BANNER_ID} className={cl(BANNER_ID, 'hide', className, { closed: isClosed })}>
      {renderContent()}
    </Main>
  );
});
